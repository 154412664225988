import gql from 'graphql-tag';

export const PAGINATE_CUSTOMERS = gql`
  query PaginateCustomers($status: String!, $skip: Int, $take: Int, $filter: [[String]]) {
    currentCompany {
      id
    }

    listAdditionalQuestions {
      id
      label
    }

    paginateCustomers(status: $status, skip: $skip, take: $take, filter: $filter) {
      data {
        id
        importedAt,
        seekerCompany {
          id
          name
        }
        latestApplication {
          status
          providerDisplayStatus
          creditLimit
          creditLimitCurrency
          creditTerms
          assignedTo {
            id
            name
          }
          dateApproved
          lastUpdatedBy {
            id
            name
          }
          updatedAt
        }
        customFieldAnswers {
          id
          text
          customField {
            id
            type
            label
            description
            required
            allowMultipleUploads
            options
            contact {
              showName
              nameRequired
              showEmail
              emailRequired
              showPhone
              phoneRequired
              showExtension
              extensionRequired
            }
            yesToggle {
              type
              allowMultipleUploads
            }
            noToggle {
              type
              allowMultipleUploads
            }
          }
        }
      },
      totalCount
    }

    pendingCustomerInvites(status: $status) {
      id
      toCompanyName
      toEmail
      type
      from {
        id
        name
      }
      referralCode
      sent
    }
  }
`;
