import React, { Dispatch, SetStateAction, useEffect, useContext } from 'react';

import { Form, useFormikContext } from 'formik';

import { includes, isEmpty } from 'lodash';
import { get } from 'lodash/fp';

import { CREDIT_APPLICANT_STATUSES, CREDIT_STATUSES_OPTIONS, MANUAL_CUSTOMER_CREDIT_STATUSES_OPTIONS } from '@/constants';

import { ICustomer } from '@/types/customer';

import { Button } from '@/components/designSystem/buttons';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { BodyText } from '@/components/designSystem/Typography';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { StyledFormButtonContainer } from '../styled';

interface IFields {
  creditLimit: string,
  creditTerms: string,
  status: string,
  providerComment: string,
}

interface IProps {
  customerData: ICustomer,
  loading: boolean,
  creditTermsOptions: string[],
  requireLimits: boolean,
  creditUpdated: boolean,
  selectedStatus: string,
  cancel: () => void,
  setSelectedStatus: Dispatch<SetStateAction<string>>
}

export const ActionsForm = ({ customerData, loading, creditTermsOptions, requireLimits, selectedStatus, setSelectedStatus, creditUpdated, cancel }: IProps) => {
  const { values, validateForm, touched, dirty } = useFormikContext();
  const { status } = values as IFields;
  const isManualCustomer = get('isManualCustomer', customerData);
  const enabled = !isEmpty(touched) || dirty;
  const currentCompany = useContext(CurrentCompanyContext);
  const currency = get('currentCompany.settings.currency', currentCompany);
  const updatedCreditOption = isManualCustomer
    ? MANUAL_CUSTOMER_CREDIT_STATUSES_OPTIONS
    : CREDIT_STATUSES_OPTIONS;

  useEffect(() => {
    setSelectedStatus(status);
    setTimeout(() => validateForm(), 25); // gross hack to ensure we disable the form button when validation changes
  }, [status]);

  return (
    <Form>
      <SelectField required name='status' label='Status' options={updatedCreditOption} />
      {requireLimits && <CurrencyField required name='creditLimit' label='Credit Limit' currency={currency} editableCurrency />}
      {requireLimits && <SelectField required creatable name='creditTerms' label='Terms' options={[
        ...creditTermsOptions.map((option) => ({ label: option, value: option }))
      ]} />}
      {
        includes([CREDIT_APPLICANT_STATUSES.APPROVED, CREDIT_APPLICANT_STATUSES.DENIED], selectedStatus) && (
          <TextAreaField
            name='providerComment'
            label='Comment For Applicant'
            placeholder='Can you please update your company address, add another more current Vendor Reference etc.?'
          />
        )}
      {includes([CREDIT_APPLICANT_STATUSES.APPROVED, CREDIT_APPLICANT_STATUSES.DENIED], selectedStatus) && (
        <CheckboxField
          name={includes([CREDIT_APPLICANT_STATUSES.DENIED], selectedStatus) ? 'seekerDenialAlertEmail' : 'seekerApprovalAlertEmail'}
        >
          <BodyText><b>Send Applicant Email Alert</b></BodyText>
        </CheckboxField>
      )}
      <StyledFormButtonContainer>
        <Button primary disabled={!enabled} loading={loading} type='submit' wide>Save</Button>
        {creditUpdated &&
          <Button secondary wide onClick={cancel}>Cancel</Button>
        }
      </StyledFormButtonContainer>
    </Form>
  )
};
