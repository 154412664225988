import { formatPhoneNumberForRequest } from "@/utils/format";
import { IManualBankValues } from "./types";

export const mapBankValuesToUpsertManualInstitutionsVariables = (
  manualBanksValues: IManualBankValues[]
) => manualBanksValues.map((bank) => ({
  institutionName: bank.bankName,
  institutionAddress: bank.bankAddress,
  contactName: bank.contactName,
  contactEmail: bank.contactEmail,
  contactPhone: formatPhoneNumberForRequest(bank.contactPhone),
  contactTitle: bank.contactTitle,
  accounts: bank.accounts.map((bankAccount) => ({
    isBusinessAccount: false,
    firstname: bankAccount.firstname,
    lastname: bankAccount.lastname,
    accountNumber: bankAccount.accountNumber,
    routingNumber: bankAccount.routingNumber,
    accountType: bankAccount.accountType,
  })),
  bankStatements: bank.bankStatements ? bank.bankStatements.map((bankStatement) => ({
    fileId: bankStatement.fileId,
    path: bankStatement.path,
    signedUrl: bankStatement.signedUrl,
  })) : [],
}));