import React, { useState } from 'react';

import { Formik, Form } from 'formik';

import { Flex } from '@/components/designSystem/Layout';
import { StyledGridColumn } from './styled';
import { Button } from '@/components/designSystem/buttons';
import { StyledFormButtonContainer } from '../styled';
import { BodyText } from '@/components/designSystem/Typography';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { EnableRequiredOptions } from '@/components/EnableRequiredOptions';
import { MutationFunctionOptions, useMutation } from '@apollo/react-hooks';
import { CREATE_EXTERNAL_REVIEW } from './queries';
import { UPDATE_CUSTOMER_SETTINGS } from '@/graphql/mutations/updateCustomerSettings';
import { get } from 'lodash/fp';
import { changeRequestSectionsToList } from '../utils';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { ICustomer } from '@/types/customer';

interface IProps {
  customerData: ICustomer,
  customerId: string
  refetchCustomerData: () => void,
  setOpen: (isOpen: boolean) => void,
}

const KEYS = {
  enabled: 'enable_personal_guarantee',
  required: 'require_personal_guarantee',
}

export const ExternalReviewForm = ({customerId, refetchCustomerData, setOpen, customerData} : IProps) => {
  const [createExternalReview] = useMutation(CREATE_EXTERNAL_REVIEW);
  const enablePersonalGuarantee = get('settings.enablePersonalGuarantee', customerData);
  const requirePersonalGuarantee = get('settings.requirePersonalGuarantee', customerData);
  const [updateCustomerSettings] = useMutation(UPDATE_CUSTOMER_SETTINGS);

  const handleSubmit = async (values : any) => {
    const variables = {
      customerId: customerId,
      changeRequestAttrs: {
        sections: changeRequestSectionsToList(get('changesRequestedSections', values)),
        comment: get('comment', values),
      }
    };
    createExternalReview({variables}).then(() => {
      showToast({
        title: 'External Review',
        description: 'Successfully created an external review',
        type: toast.TYPE.SUCCESS,
      });
      setOpen(false)
      refetchCustomerData()
    });
  }

  const initialValues = {
    changesRequestedSections: {
      company_info: false,
      bank_info: false,
      vendors: false
    }
  }

  const handlePersonalGuaranteeChange = (state: any) => handleUpdateCompanySettings({variables: {customerId, settings: state}});

  const handleUpdateCompanySettings = (variables: MutationFunctionOptions) => updateCustomerSettings(variables)
    .then(() => {
      showToast({
        title: 'Settings Successfully Update!',
        description: 'Settings Successfully Update',
        type: toast.TYPE.SUCCESS,
      });
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Settings were not updated',
        type: toast.TYPE.ERROR,
      });
    });

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <Form style={{ gap: '1rem' }}>
        <Flex direction='column' gap='0.5rem'>
          <BodyText>Please, choose application sections which require changes</BodyText>
          <StyledGridColumn column={2}>
            <CheckboxField name='changesRequestedSections.company_info'>
              <BodyText>Company Information</BodyText>
            </CheckboxField>
            <CheckboxField name='changesRequestedSections.bank_info'>
              <BodyText>Bank/Financial Information</BodyText>
            </CheckboxField>
            <CheckboxField name='changesRequestedSections.vendors'>
              <BodyText>Vendor Information</BodyText>
            </CheckboxField>
            <CheckboxField disabled checked name='signature'>
              <BodyText>Signature is required</BodyText>
            </CheckboxField>
          </StyledGridColumn>
          <EnableRequiredOptions
            label='Personal Guarantee'
            keys={KEYS}
            canSeeEditControl={true}
            enabled={enablePersonalGuarantee}
            required={requirePersonalGuarantee}
            handleChange={handlePersonalGuaranteeChange}
          />
        </Flex>

        <TextAreaField
          name='comment'
          label='Comment For Applicant'
          placeholder='Can you please update your company address, add another more current Vendor Reference etc.?'
        />
        <StyledFormButtonContainer>
          <Button type='submit' primary wide> Submit </Button>
        </StyledFormButtonContainer>
      </Form>
    </Formik>
  )};
