import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useField, ErrorMessage } from 'formik';

import { FieldLabel } from './FieldLabel';
import { StyledErrorText, StyledFieldContainer, StyledDateInput } from './styled';

interface IProps {
  name?: string,
  label: string,
  required?: boolean,
  portal?: boolean,
  minDate?: Date,
  maxDate?: Date,
  autoFocus?: boolean,
}

export const DatePickerField = ({
  minDate,
  maxDate = new Date(),
  portal,
  required,
  name = '',
  label,
  autoFocus,
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { setValue, setTouched } = helpers;

  const computedMaxDate = minDate ? undefined : maxDate;

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <DatePicker
        id={name}
        dateFormat='MMMM do, yyyy'
        showYearDropdown
        showMonthDropdown
        dropdownMode='select'
        showPopperArrow={false}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(value) => setValue(value ?? '')}
        onBlur={() => setTouched(true)}
        minDate={minDate}
        maxDate={computedMaxDate}
        customInput={<StyledDateInput error={showError} />}
        withPortal={portal}
        autoFocus={autoFocus}
      />
      {(showError && name) &&
        <StyledErrorText>
          { (showError && name) ? <ErrorMessage name={name} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  )
};
