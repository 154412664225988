import React, { useEffect, useContext, useRef, useState } from 'react';
import DataGrid from "devextreme-react/data-grid";
import Breadcrumb from '@/components/Breadcrumb';
import CustomStore from 'devextreme/data/custom_store';

import { useApolloClient } from '@apollo/client';
import { PAGINATE_CUSTOMERS } from './queries';

import { get, getOr, filter } from 'lodash/fp';

import { CREDIT_APPLICANT_STATUSES } from '@/constants';

import { ICustomField } from '@/types/customField';
import { ICustomer } from '@/types/customer';

import { useRedirect } from '@/providers/Redirect/context';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { BodyText, H2 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { AddCustomerModal } from '@/modals/AddCustomerModal';
import { DashboardGrid } from '@/components/DashboardGrid';
import { Button } from '@/components/designSystem/buttons';

import { COLUMNS } from './constants';
import {
  StyledContainer,
  StyledCustomersInfo,
  StyledNewCustomerWrapper,
  StyledAccountActivationWrapper,
  StyledTableInfo,
  StyledEmptyStateWrapper
} from './styled';
import { ActivationMessage } from './ActivationMessage';
import styled from 'styled-components';
import { noop } from 'lodash';
const StyledMessageWrapper = styled.div`
  height: 100%;
  padding: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
`;

export const CustomersImportedDashboard = () => {
  const [totalCustomers, setTotalCustomers] = useState(0);
  const dataGridRef = useRef<DataGrid<unknown, unknown>>(null);
  const client = useApolloClient();

  const currentCompanyData = useContext(CurrentCompanyContext);
  const enabledReview = getOr(false, 'currentCompany.planInfo.enabledReview', currentCompanyData);
  const currentCompanyName = get('currentCompany.name', currentCompanyData);

  const canAddNewCustomer = true;

  const [isAddingCustomerOpen, setAddingCustomerOpen] = useState(false);

  const { toCustomerPage } = useRedirect();

  const onRowClick = ({ data }: any) => toCustomerPage(data.id);

  const renderTableInfo = () => {
    return (
      <>
        <StyledCustomersInfo>
          <div>
            <BodyText bold>{totalCustomers}</BodyText> <BodyText color='secondary'>Customers</BodyText>
          </div>
        </StyledCustomersInfo>
        {canAddNewCustomer ? (
          <StyledNewCustomerWrapper>
            <Button primary onClick={() => setAddingCustomerOpen(true)}>New Customer</Button>
          </StyledNewCustomerWrapper>
        ) : (
          <StyledAccountActivationWrapper>
            <ActivationMessage />
          </StyledAccountActivationWrapper>
        )}
      </>
    )
  }

  const [customStore] = useState(new CustomStore({
    key: "id",
    load: async (options: any) => {
      const params = getOr([], 'filter', options)
      const filterParams = filter((x) => Array.isArray(x), params)
      return client.query({
        query: PAGINATE_CUSTOMERS,
        variables: {status: 'imported', skip: options.skip, take: options.take, filter: filterParams},
      }).then((result) => {
        const customers = result.data.paginateCustomers.data.map((cust: any) => {
          const application = cust.latestApplication;
          return {
            id: cust.id,
            company: cust.seekerCompany.name,
            status: application.status,
            creditLimit: application.creditLimit,
            creditTerms: application.creditTerms,
            assignedTo: cust.assignedTo,
            lastUpdatedBy: application.lastUpdatedBy.name,
            lastUpdatedOn: application.updatedAt,
            importedAt: cust.importedAt
        }});
        setTotalCustomers(result.data.paginateCustomers.totalCount)
        return Promise.resolve({
          data: customers,
          totalCount: result.data.paginateCustomers.totalCount
        });
      });
    }
  }));

  return (
    <>
      { enabledReview ? (
        <StyledContainer>
          <Breadcrumb />
          <StyledTableInfo>
            {renderTableInfo()}
          </StyledTableInfo>
            <DashboardGrid
              dataSource={customStore}
              columns={COLUMNS}
              onRowClick={onRowClick}
              dataGridRef={dataGridRef}
              isToolbarAfterMargin
              toolbarRightMargin={canAddNewCustomer ? '10rem' : '34rem'}
            />
          <AddCustomerModal
            currentCompanyName={currentCompanyName}
            isOpen={isAddingCustomerOpen}
            setIsOpen={setAddingCustomerOpen}
            onSuccess={noop}
          />
        </StyledContainer>
      ) :
        (
          <StyledMessageWrapper>
            <H2>
            To upgrade and add our Review Module,<br/>
            please schedule a call with your Nectarine Credit account executive.
            </H2>
            <Button href='https://calendly.com/alex-1257' target='_blank' primary wide>Schedule a call</Button>
          </StyledMessageWrapper>
        )
      }
    </>
  )
};
