import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';

import { getOr } from 'lodash/fp';

import { MANUAL_ACCOUNT_TYPES } from '@/constants';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { RadioButton } from '@/components/designSystem/Form/RadioButton';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { StyledErrorText, StyledFormRow } from '@/components/designSystem/Form/styled';

import { StyledClose } from './styled';
import { IManualBankFormValues } from '../types';
import { Flex } from '@/components/designSystem/Layout';

interface IProps {
  parentKey: string,
  index: number,
  handleRemoveClick: () => void,
  disabled: boolean
}

export const AccountSection = ({ parentKey, index, disabled, handleRemoveClick }: IProps) => {
  const { values, setFieldValue} = useFormikContext<IManualBankFormValues>();

  return (
    <>
      <StyledFormRow>
        <TextField
          type='text'
          name={`${parentKey}.firstname`}
          label={"Account Holder's First Name"}
          placeholder='John'
        />
        <TextField
          type='text'
          name={`${parentKey}.lastname`}
          label={"Account Holder's Last Name"}
          placeholder='Smith'
        />
      </StyledFormRow>
      <StyledFormRow>
        <SelectField required name={`${parentKey}.accountType`} label='Account Type' options={MANUAL_ACCOUNT_TYPES} disabled={disabled} />
        <TextField required type='text' name={`${parentKey}.routingNumber`} label='Routing/Transit Number' placeholder='e.g. 123456789' disabled={disabled} />
        <TextField required type='text' name={`${parentKey}.accountNumber`} label='Account Number' placeholder='e.g. 000123456789' disabled={disabled} />
      </StyledFormRow>
    </>
  );
};
