import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { useRedirect } from '@/providers/Redirect/context';
import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { BodyText } from '@/components/designSystem/Typography';
import { ICustomer } from '@/types/customer';

import { FlexColumnItemsCenter } from '../components/designSystem/Layout';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Container = styled(FlexColumnItemsCenter)`
  justify-content: center;
  text-align: center;
  padding: 2rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

interface IProp {
  customer: ICustomer
}

export const IncompleteCreditApplicationModal = ({customer}: IProp) => {
  const { toSeekerSignup } = useRedirect();

  const [isOpen, setIsOpen] = useState(false);
  const providerCompany = customer.providerCompany;

  useEffect(() => {
    if (!customer.onboardComplete) {
      setIsOpen(true);
    }
  }, []);

  return (
    <StyledModal
      title='Continue your Application'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      blockClose
    >
      <Container>
        <BodyText>It looks like you have a Credit Application in progress for {providerCompany.name}</BodyText>
        <BodyText>Would you like to complete it now?</BodyText>
        <StyledButton
          wide
          primary
          onClick={() => toSeekerSignup({ companyId: providerCompany.id }, `?cid=${customer.id}`)}
        >
          Finish Application
        </StyledButton>
        <StyledButton wide secondary onClick={() => setIsOpen(false)}>Not Now</StyledButton>
      </Container>
    </StyledModal>
  );
};
