import React, { useContext } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { get, getOr } from 'lodash/fp';
import { formatCurrency } from '@/utils/money';
import { TagForm } from './TagForm';

import { useQuery } from '@apollo/react-hooks';
import { GET_TAGS_BY_PROVIDER_ID } from '@/graphql/queries/getTag';

import { ICustomer } from '@/types/customer';
import { ILabelValue } from '@/types/field';
import { Row } from '@/components/designSystem/Form/styled';
import { BodyText, H1, H2 } from '@/components/designSystem/Typography';
import { StatusLabel } from '@/components/Label/StatusLabel';
import { Flex } from '@/components/designSystem/Layout';
import { AssigneeForm } from './AssigneeForm';
import { IUser, ICompanyUser } from '@/types/companyUser';

import {
  StyledMainInfoContainer,
  StyledMainInfoValuesContainer,
} from '../styled';

const MainInfoValue = ({ label, value }: ILabelValue) => (
  <Flex direction='column' justify='flex-end'>
    <H2>{value}</H2>
    <BodyText color='secondary'>{label}</BodyText>
  </Flex>
);

interface IProps {
  customerData?: ICustomer,
  refetchCustomerData: () => void,
}

interface AssigneeProps {
  index: number,
  name: string,
  id: string
}

export const MainInfo = ({ customerData, refetchCustomerData}: IProps) => {
  const [companyId] = useLocalStorage('company-id');
  const application = get('latestApplication', customerData);
  const applicationId = get('id', application);
  const isManualCustomer = get('isManualCustomer', customerData);
  const creditLimit = getOr('--', 'creditLimit', application);
  const creditLimitCurrency = getOr('', 'creditLimitCurrency', application);
  const creditTerms = getOr('--', 'creditTerms', application);
  const status = getOr('', 'providerDisplayStatus', application);
  const assignedTo = getOr('', 'assignedTo.id', application);
  const isPending = status === 'pending_approval';
  const reviewedAt = get('latestApplication.reviewedAt', customerData);
  const scheduleDate = get('scheduleDate', customerData);

  const currentCompany = useContext(CurrentCompanyContext);
  const currentCompanyId = get('currentCompany.id', currentCompany) || companyId;
  const users: IUser[] = getOr([], 'listUsers', currentCompany?.currentCompany);

  const assignees: AssigneeProps[] = users
    .filter(user => user.email && !user.email.includes('nectarinecredit.com'))
    .map((user, index) => ({
      index,
      name: user.name,
      id: user.id,
    }));

  return (
    <StyledMainInfoContainer>
      <Flex justify='space-between' align='center'>
        <div>
          <Row>
            <StatusLabel value={status} />
            {
              isManualCustomer ?
                <StatusLabel value='Manual Customer' />
                : null
            }
            {reviewedAt && <StatusLabel value={`Reviewed at: ${reviewedAt}`} />}
            {scheduleDate && <StatusLabel value={`Schedule Date: ${scheduleDate}`} />}
          </Row>
          <H1 bold>{get('seekerCompany.name', customerData)}</H1>
          <Row>
            <AssigneeForm data={assignees} assignedTo={assignedTo} applicationId={applicationId} refetchCustomerData={refetchCustomerData}/>
            <TagForm currentCompanyId={currentCompanyId!} applicationId={applicationId!} />
          </Row>
        </div>
        <StyledMainInfoValuesContainer>
          <MainInfoValue
            value={typeof creditLimit === 'number' ? formatCurrency(creditLimit, true, creditLimitCurrency) : '--'}
            label={isPending ? 'Requested Credit limit' : 'Credit limit'}
          />
          <MainInfoValue
            value={creditTerms || '--'}
            label={isPending ? 'Requested Terms' : 'Terms'}
          />
        </StyledMainInfoValuesContainer>
      </Flex>
    </StyledMainInfoContainer>
  );
};
