import React, { useState } from 'react';
import { addMonths } from 'date-fns';

import { useMutation } from '@apollo/react-hooks';

import { get } from 'lodash/fp';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { formatDate } from '@/utils/date';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { H4, BodyText } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';
import { StyledGridContainer, StyledGridColumn, StyledCustomDatePickerContainer } from './styled';
import { Formik, Form, Field, useField } from 'formik';
import { Button } from '@/components/designSystem/buttons';
import { StyledFormButtonContainer } from '../styled';
import { CREATE_SCHEDULE_REVIEW, CANCEL_SCHEDULE_REVIEW } from './queries';
import { StyledSelect } from '@/components/designSystem/Form/styled';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { ILabelValue } from '@/types/field';
import { ICustomer } from '@/types/customer';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { ReactComponent as XClose } from '@/assets/x_close.svg';

interface IProps {
  customerData: ICustomer,
  customerId: string,
  setOpen: (isOpen: boolean) => void,
  refetchCustomerData: () => void,
  creditTermsOptions: string[]
}

type ScheduleOption = {
  label: string;
  value: string;
};

export const ScheduleReviewForm = ({customerData, customerId, refetchCustomerData, setOpen} : IProps) => {
  const scheduleDate = get('scheduleDate', customerData);
  const [createScheduleReview] = useMutation(CREATE_SCHEDULE_REVIEW);
  const [cancelScheduleReview] = useMutation(CANCEL_SCHEDULE_REVIEW);

  const initialValues = {
    customerId: customerId,
    scheduleDate: customerData.scheduleDate || '',
    notes: ''
  }

  const formValidation = () => Yup.object().shape({
    customerId: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    scheduleDate: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    notes: Yup.string(),
  });

  const handleSubmit = async (values : any) => {
    const variables = {
      customerId: customerId,
      scheduleDate:  formatDate(get('scheduleDate', values)),
      notes: get('notes', values)
    }
    createScheduleReview({variables})
      .then(() => {
        showToast({
          title: 'Schedule Review',
          description: 'Successfully created an schedule review',
          type: toast.TYPE.SUCCESS,
        });
        setOpen(false)
        refetchCustomerData()
      })
  }

  const handleCancel = async () => {
    cancelScheduleReview({variables: {customerId: customerId}})
      .then(() => {
        showToast({
          title: 'Review',
          description: 'Review has been sucessfully cancelled',
          type: toast.TYPE.SUCCESS,
        });
        refetchCustomerData()
      })
  }

  const PresetDateField = (props: any) => {
    const [ field, _meta, helpers ] = useField<Date | undefined>(props);
    const [custom, setCustom] = useState<boolean>(false);

    const scheduleOptions: ScheduleOption[] = [
      { label: 'In 1 Month', value: addMonths(new Date(), 1).toISOString() },
      { label: 'In 3 Months', value: addMonths(new Date(), 3).toISOString() },
      { label: 'In 6 Months', value: addMonths(new Date(), 6).toISOString() },
      { label: 'In 1 Year', value: addMonths(new Date(), 12).toISOString() },
      { label: 'Custom', value: 'custom'},
    ]

    const formatDateString = (value: string) => {
      if (value !== 'custom') {
        return new Date(value).toLocaleDateString('en-US');
      }
      return '';
    };

    const formattedOptions = scheduleOptions.map(option => ({
      label: option.value !== 'custom' ? `${option.label} (${formatDateString(option.value)})` : option.label,
      value: option.value,
    }));

    const { setValue } = helpers;

    const cancelCustom = () =>  {
      setCustom(false);
      setValue(undefined);
    }

    return (
      <StyledGridColumn column={2}>
        <FieldLabel name={props.name} required>Review Date</FieldLabel>
        {!custom && 
          <StyledSelect
            options={formattedOptions}
            onChange={(option: ILabelValue) => {
              if(option.value == 'custom') {
                setCustom(true);
              } else {
                setCustom(false);
                setValue(new Date(option.value))
              }
            }}
          />
        }
        {custom ?
          <StyledCustomDatePickerContainer>
            <DatePickerField
              name={field.name}
              label=''
              portal={true}
              minDate={new Date()}
              autoFocus={true}
            />
            <XClose onClick={cancelCustom} />
          </StyledCustomDatePickerContainer>
        :
          <Field type='hidden' name={field.name} />
        }
      </StyledGridColumn>
    )
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <Form>
        {scheduleDate ?
          <Flex gap='1rem' align='center'>
            <H4 bold>Schedule Date: </H4>
            <BodyText>{scheduleDate}</BodyText>
          </Flex> :
          <StyledGridContainer>
            <PresetDateField name='scheduleDate' />
            <StyledGridColumn column={2}>
              <Flex direction='column'>
                <H4 bold>Notes</H4>
                <TextAreaField name='notes' />
              </Flex>
            </StyledGridColumn>
          </StyledGridContainer>
        }
        <StyledFormButtonContainer>
          {scheduleDate ?
            <Button type='button' secondary wide onClick={handleCancel}>Cancel</Button> :
            <Button type='submit' primary wide>Schedule</Button>
          }
        </StyledFormButtonContainer>
      </Form>
    </Formik>
  )
};
