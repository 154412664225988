import React, { Dispatch, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik } from 'formik';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { IVRCustomField } from '@/types/vrCustomField';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { VendorOnboardValuesContext } from '../../context';
import { referenceStepValidation } from './validation';
import { ReferenceStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_REFERENCE } from '../../reducer';
import { IReferenceValues } from './types';

export interface IReferenceStepProps {
  CACompanyName: string,
  customFields: IVRCustomField[],
  refData: any,
  dispatch: Dispatch<IOnboardValuesAction>,
}

export const ReferenceStep = ({ CACompanyName, customFields, refData, dispatch }: IReferenceStepProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const onboardValues = useContext(VendorOnboardValuesContext);
  console.log(refData, 'refdata');

  const dispatchValues = (values: IReferenceValues) => {
    if (!onboardValues) {
      return;
    }

    dispatch({ type: UPDATE_REFERENCE, payload: values })
    history.push({ pathname: `${onboardValues.basePath}/complete`, search });
  }

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.reference}
      validationSchema={referenceStepValidation(customFields)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <ReferenceStepForm CACompanyName={CACompanyName} customFields={customFields} refData={refData} />
    </Formik>
  )
};
