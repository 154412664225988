import React, { useContext, useRef, useState } from 'react';
import DataGrid from "devextreme-react/data-grid";
import Breadcrumb from '@/components/Breadcrumb';

import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { DASHBOARD_CUSTOMERS } from '@/graphql/queries/dashboardCustomers';

import { get, getOr } from 'lodash/fp';

import { CREDIT_APPLICANT_STATUSES } from '@/constants';

import { ICustomField } from '@/types/customField';
import { ICustomer } from '@/types/customer';

import { useRedirect } from '@/providers/Redirect/context';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { AddCustomerModal } from '@/modals/AddCustomerModal';
import { DashboardGrid } from '@/components/DashboardGrid';
import { Button } from '@/components/designSystem/buttons';

import { useCustomersData } from './useCustomersData';
import { COLUMNS } from './constants';
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledCustomersInfo,
  StyledNewCustomerWrapper,
  StyledAccountActivationWrapper,
  StyledTableInfo,
  StyledTitleWrapper,
  StyledEmptyStateWrapper
} from './styled';
import { EmptyState } from './EmptyState';
import { ActivationMessage } from './ActivationMessage';

export const CustomersArchivedDashboard = () => {
  const { loading, data, refetch } = useQuery(DASHBOARD_CUSTOMERS, {
    variables: {status: 'archived'},
    fetchPolicy: 'cache-and-network'
  });
  const dataGridRef = useRef<DataGrid<unknown, unknown>>(null);

  const currentCompanyData = useContext(CurrentCompanyContext);

  const currentCompanyName = get('currentCompany.name', currentCompanyData);

  const customers = getOr([], 'listCustomers', data);
  const totalCustomers = customers.length;
  const customersInProgress = customers.filter((c: ICustomer) => get('latestApplication.status', c) === CREDIT_APPLICANT_STATUSES.IN_PROGRESS).length;
  const customersPending = customers.filter((c: ICustomer) => get('latestApplication.status', c) === CREDIT_APPLICANT_STATUSES.PENDING_APPROVAL).length;

  const canAddNewCustomer = true;

  const additionalQuestions = getOr([], 'listAdditionalQuestions', data);

  const columns = COLUMNS
    .concat(additionalQuestions
      .map((additionalQuestion: ICustomField) => (
        {
          dataField: additionalQuestion.id,
          caption: additionalQuestion.label,
          allowHeaderFiltering: true,
          visible: false,
        }
      ))
    )

  const [isAddingCustomerOpen, setAddingCustomerOpen] = useState(false);

  const { toCustomerPage } = useRedirect();

  const customersData = useCustomersData(data);

  const onRowClick = ({ data }: any) => toCustomerPage(data.id);

  const renderTableInfo = () => {
    if (loading) {
      return null;
    }
    return (
      <>
        {canAddNewCustomer ? (
          <StyledNewCustomerWrapper>
            <Button primary onClick={() => setAddingCustomerOpen(true)}>New Customer</Button>
          </StyledNewCustomerWrapper>
        ) : (
          <StyledAccountActivationWrapper>
            <ActivationMessage />
          </StyledAccountActivationWrapper>
        )}
      </>
    )
  }

  return (
    <StyledContainer>
      <Breadcrumb />
      <StyledTableInfo>
        {renderTableInfo()}
      </StyledTableInfo>
      {(!loading && !customersData.length) && (
        <StyledEmptyStateWrapper>
          <EmptyState handleInviteCustomer={() => setAddingCustomerOpen(true)} buttonsVisible={canAddNewCustomer} />
        </StyledEmptyStateWrapper>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <DashboardGrid
          dataSource={customersData}
          columns={columns}
          onRowClick={onRowClick}
          dataGridRef={dataGridRef}
          isToolbarAfterMargin
          toolbarRightMargin={canAddNewCustomer ? '10rem' : '34rem'}
        />
      )}
      <AddCustomerModal
        currentCompanyName={currentCompanyName}
        isOpen={isAddingCustomerOpen}
        setIsOpen={setAddingCustomerOpen}
        onSuccess={() => refetch()}
      />
    </StyledContainer>
  )
};
