import React from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMER_AS_SEEKER } from '@/graphql/queries/getCustomer';

import { get } from 'lodash/fp';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ContactInfoSection } from '@/components/_oldComponents/sections/ContactInfoSection';
import { CompanyInfoSection } from '@/components/_oldComponents/sections/CompanyInfoSection';
import { VendorReferencesSection } from '@/components/_oldComponents/sections/VendorReferencesSection';
import { CompanyContactSection } from '@/components/_oldComponents/sections/CompanyContactSection';

import { SeekerFinanicalHealthSection } from './SeekerFinanicalHealth';
import { AdditionalRefRequiredMessage } from './AdditionalRefRequiredMessage';
import { AddVendorReferenceButton } from './AddVendorReferenceButton';
import { ApplicationCommentSection } from './ApplicationCommentSection';
import { SupportingInfoSection } from './SupportingInfoSection/index.tsx';
import { CreditAgreementSection } from './CreditAgreementSection';
import { IReference } from '@/types/reference';
import { IncompleteCreditApplicationModal } from '@/modals/IncompleteCreditApplicationModal';

interface IParams {
  customerId: string,
}

interface IVendorReferencesSectionChildrenProps {
  receivedReferences: IReference[],
  numRequiredReferences: number,
}

export const ApplicantPage = () => {
  const { customerId } = useParams<IParams>();
  const { data, loading, refetch } = useQuery(GET_CUSTOMER_AS_SEEKER, { variables: { customerId }, fetchPolicy: 'no-cache' });
  const customer = get('getCustomerAsSeeker', data);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <TwoColumnContainer.Container
      title={loading ? 'Loading...' : `Credit Application for ${get('providerCompany.name', customer)}`}
      backTitle='Credit Request Dashboard'
      backTo='/dashboard/requests'
    >
      <TwoColumnContainer.MainColumn>
        <ApplicationCommentSection customer={customer} />

        <CreditAgreementSection customer={customer} refetch={refetch} />
        <CompanyContactSection customer={customer} />
        <SeekerFinanicalHealthSection customer={customer} refetch={refetch} />
        <VendorReferencesSection customer={customer} >
          {({ receivedReferences, numRequiredReferences }: IVendorReferencesSectionChildrenProps) => {
            const additionalReferencesRequired = numRequiredReferences - receivedReferences.length;

            return (
              <>
                {additionalReferencesRequired > 0 && <AdditionalRefRequiredMessage number={additionalReferencesRequired} />}
                <AddVendorReferenceButton customer={customer} refetch={refetch} />
              </>
            );
          }}
        </VendorReferencesSection>
        <SupportingInfoSection customer={customer} refetch={refetch} />

      </TwoColumnContainer.MainColumn>

      <TwoColumnContainer.SecondaryColumn>
        <CompanyInfoSection customer={customer} />
        <ContactInfoSection customer={customer} />
      </TwoColumnContainer.SecondaryColumn>
      <IncompleteCreditApplicationModal customer={customer} />
    </TwoColumnContainer.Container>
  );
};
