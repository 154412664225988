import gql from 'graphql-tag';

export const REMIND_VENDOR = gql`
  mutation RemindVendor($referralId: Int!) {
    remindVendor(referralId: $referralId)
  }
`;

export const REMIND_VENDOR_BY_CP = gql`
  mutation RemindVendorByCp($referralId: Int!) {
    remindVendorByCp(referralId: $referralId)
  }
`;
