import React, { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { get, getOr } from 'lodash/fp';

import { H4 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';
import { StyledGridContainer, StyledGridColumn, StyledInternalForm } from './styled';
import { CustomField } from '@/components/CustomField/CustomField';
import { ICustomField } from '@/types/customField';
import Tiptap from '@/components/designSystem/Form/Editor/Tiptap';
import { Formik, Form } from 'formik';
import { Button } from '@/components/designSystem/buttons';
import { StyledFormButtonContainer } from '../styled';
import { mapCustomFieldsToFormInitialValues } from '@/utils/customFields';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { CREATE_REVIEW, INTERNAL_REVIEW } from './queries';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { mapFormValuesToFieldAnswers } from '@/utils/customFields';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { ICustomer } from '@/types/customer';

interface IProps {
  customerData: ICustomer,
  setOpen: (isOpen: boolean) => void,
  refetchCustomerData: () => void,
  creditTermsOptions: string[]
}

export const InternalReviewForm = ({customerData, refetchCustomerData, setOpen, creditTermsOptions} : IProps) => {
  const customerId = get('id', customerData);
  const { data, loading } = useQuery(INTERNAL_REVIEW, {variables: {customerId: customerId}});
  const [createReview] = useMutation(CREATE_REVIEW);
  const customFields = getOr([], 'listCustomFields', data);

  const currentCompany = useContext(CurrentCompanyContext);
  const settings = get('currentCompany.settings', currentCompany);
  const application = get('getCustomerAsProvider.latestApplication', data);
  const initialValues = {
    application: {
      creditLimit: get('creditLimit', application),
      creditLimitCurrency: getOr(settings.currency, 'creditLimitCurrency', application),
      creditTerms: get('creditTerms', application),
      analysis: '',
    },
    customFieldAnswers: mapCustomFieldsToFormInitialValues(customFields, customerData.customFieldAnswers)
  }

  const handleSubmit = async (values : any) => {
    const variables = {
      customerId: customerId,
      customFieldAnswers: mapFormValuesToFieldAnswers(values.customFieldAnswers, customFields),
      application: values.application
    }
    createReview({variables})
      .then(() => {
        showToast({
          title: 'Internal Review',
          description: 'Successfully created an internal review',
          type: toast.TYPE.SUCCESS,
        });
        setOpen(false)
        refetchCustomerData()
      })
    ;
  }

  if (loading) return <LoadingSpinner />;

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <StyledInternalForm>
        <StyledGridContainer>
          {customFields.map((p: ICustomField) => (
            <CustomField
              key={p.id}
              name={`customFieldAnswers.${p.id}`}
              {...p}
              options={p.options}
              settings={settings}
            />
          ))}
          <CurrencyField name="application.creditLimit" label='Credit Limit' required={true} currency={settings.currency} />
          <SelectField
            name='application.creditTerms'
            label='Credit Terms'
            options={[
              ...creditTermsOptions.map(
                (option: string) => ({ label: option, value: option })
              )
            ]} />
          <SelectField
            name='application.status'
            label='Status'
            defaultValue='approved'
            options={[
              {label: 'Pending Review', value: 'pending_review'},
              {label: 'Approved', value: 'approved'},
              {label: 'Denied', value: 'denied'}]}
          />
          <StyledGridColumn column={2}>
            <Flex direction='column'>
              <H4 bold>Analysis</H4>
              <Tiptap name='application.analysis' />
            </Flex>
          </StyledGridColumn>
        </StyledGridContainer>
        <StyledFormButtonContainer>
          <Button type='submit' primary wide> Submit </Button>
        </StyledFormButtonContainer>
      </StyledInternalForm>
    </Formik>
  )
};
