import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { TextField } from '@/components/designSystem/Form/TextField';
import { Flex, FlexColumn, FlexRow } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { SetCreditTermsOptionsModal } from '@/modals/SetCreditTermsOptionsModal';

import { EnableRequiredOptions } from '@/components/EnableRequiredOptions';
import { ISettings } from '@/types/settings';

const Container = styled(FlexRow)`
  width: 100%;
  align-items: center;

  & > * {
    margin-left: 1rem;
  }

  & > :first-child {
    margin-left: 0;
  }
`;

interface IProps {
  creditTermsOptions: string[],
  refetch: () => void,
  settings: ISettings
}

export const CreditTermFields = ({
  creditTermsOptions,
  refetch,
  settings
}: IProps) => {
  const [isSetOptionsOpen, setSetOptionsOpen] = useState(false);
  const [creditTermsOptionsState, setСreditTermsOptionsState] = useState(creditTermsOptions);

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);
  const handleUpdateCompanySettings = (variables: any) => updateCompanySettings({variables: {settings: variables}})
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
      refetch();
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Company settings Update Failed.',
        type: toast.TYPE.ERROR,
      });
    });

  useEffect(() => {
    setСreditTermsOptionsState(creditTermsOptions);
  }, [creditTermsOptions]);

  return (
    <Flex style={{width: "100%"}}>
      <Container>
        {/* This TextField name is fake. We need it for formik fields.
        If you need to enable this text field at some point, you will need to fix name. */}
        <FlexColumn style={{width: "100%"}}>
          <TextField type='text' name='creditTerms' disabled label='Credit Terms' placeholder={creditTermsOptions[0]} />
          <EnableRequiredOptions keys={{enabled: "enable_credit_terms", required: "require_credit_terms"}} enabled={settings.enableCreditTerms} required={settings.requireCreditTerms} handleChange={handleUpdateCompanySettings} />
        </FlexColumn>
        <Button primary onClick={() => setSetOptionsOpen(true)}>Set Options</Button>
      </Container>
      <SetCreditTermsOptionsModal
        isOpen={isSetOptionsOpen}
        setIsOpen={setSetOptionsOpen}
        onSuccess={refetch}
        creditTermsOptions={creditTermsOptionsState}
        setСreditTermsOptionsState={setСreditTermsOptionsState}
      />
    </Flex>
  );
};
