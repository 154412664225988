import React, { Dispatch, SetStateAction } from 'react';

import { VIEWS } from '.';
import { StyledContainer, StyledCustomerOptionsContainer } from './styled';
import { ListOption } from '@/components/ListOption';

interface IProps {
  setActiveView: Dispatch<SetStateAction<keyof typeof VIEWS>>,
}

export const CustomerTypes = ({ setActiveView }: IProps) => {

  const handleOptionClick = (view: keyof typeof VIEWS) => {
    setActiveView(view);
  }

  const options = [
    {
      title: 'Invite Credit Customer:',
      description: 'Click here to invite clients to complete your credit application and sign your terms and conditions.',
      handleOptionClick: () => handleOptionClick(VIEWS.CREDIT_CUSTOMER),
    },
    {
      title: 'Add Manual Customer:',
      description: `Click here if your client has submitted a PDF, Word, or other documentation outside of the Nectarine Credit platform. 
      You can still ask them to sign your Terms and Conditions.`,
      handleOptionClick: () => handleOptionClick(VIEWS.MANUAL_CUSTOMER),
    },
    {
      title: 'Import Customer',
      description: `Click here to add your existing client. Once added, you will be able to start an review process on them.`,
      handleOptionClick: () => handleOptionClick(VIEWS.IMPORT_CUSTOMER),
    }
  ]

  return (
    <StyledContainer>
      <StyledCustomerOptionsContainer>
        {options.map((option) => (
          <ListOption
            key={option.title}
            title={option.title}
            description={option.description}
            handleOptionClick={option.handleOptionClick}
          />
        ))}
      </StyledCustomerOptionsContainer>
    </StyledContainer>
  )
};
