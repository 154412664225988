import React, { useContext } from 'react';

import { Formik, Form } from 'formik';

import { get, noop } from 'lodash/fp';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { StyledLink } from '@/components/designSystem/Typography';

import { ReactComponent as LockIllustration } from '@/assets/illustrations/lock.svg';

import { CompanyDetailsSection } from './CompanyDetailsSection';
import { CompanyAddressSection } from './CompanyAddressSection';
import { OwnerSection } from './OwnerSection';
import { OtherPersonSection } from './OtherPersonSection';
import { VendorSection } from './VendorSection';
import { BankInfoSection } from './BankInfoSection';
import { CreditTermsSection } from './CreditTermsSection';
import { OtherInfoSection } from './OtherInfoSection';
import { Container, StyledTitleCallout } from './styled';
import { LoadingSpinner } from '@/components/LoadingSpinner';

export const CreditApplicationCustomerInformation = () => {

  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const initialSettings = get('currentCompany.settings', currentCompanyData);
  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  return (
    <Container>
      <Formik initialValues={undefined as any} onSubmit={noop} validateOnChange>
        <Form>
          <StyledTitleCallout
            Icon={<LockIllustration />}
            title='The following fields are required for all credit applications'
          >
            To add new customizable fields, <StyledLink className='bold-link' to='/dashboard/application/custom'>click here</StyledLink> or on "Customizable Fields" item in the above menu.
          </StyledTitleCallout>
          <CompanyDetailsSection />
          <CompanyAddressSection initialSettings={initialSettings} refetch={refetchCurrentCompany} />
          <OwnerSection initialSettings={initialSettings} refetch={refetchCurrentCompany} />
          <OtherPersonSection />
          <VendorSection />
          <BankInfoSection />
          <CreditTermsSection settings={initialSettings} />
          <OtherInfoSection initialSettings={initialSettings} refetch={refetchCurrentCompany} />
        </Form>
      </Formik>
    </Container>
  )
};
