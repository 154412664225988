import React, { useContext, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useAutoscrollToError } from '@/views/onboard/useAutoscrollToError';
import { Flex, FlexColumn } from '@/components/designSystem/Layout';
import { BodyText, H2 } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { ReactComponent as Plus } from '@/assets/plus.svg';
import { IFinancialHealthValues } from '../../types';
import { StyledCheckboxWrapper, StyledForm, StyledNextButton, StyledTitleContainer } from '../../../../styled';
import { StyledCalloutAction, StyledCalloutContainer } from '../../styled';
import { initialManualBankFields } from '../../initialValues';
import { ManualBankSection } from './ManualBankSection';
import { useNoBankInfo } from './useNoBankInfo';
import { ConnectBankButton } from '@/components/ConnectBankButton';
import { isFinicityConnected } from '../../functions';

interface IProps {
  onboardValues: any,
  finicityCustomerData: any,
  refetchFinicityCustomerData: any,
  requireBankInfo: boolean,
  requestBankStatements: boolean,
  automaticBankVerificationEnabled: boolean,
}

export const ManualBankView = ({ onboardValues, finicityCustomerData, refetchFinicityCustomerData, requireBankInfo, requestBankStatements, automaticBankVerificationEnabled } : IProps) => {
  const { values, setFieldValue, errors, isSubmitting } = useFormikContext<IFinancialHealthValues>();

  useAutoscrollToError(errors, isSubmitting)

  useEffect(() => {
    setFieldValue('isManualBank', true)
  }, []);

  const {
    noBankInfo,
    noBankInfoClickHandle
  } = useNoBankInfo({
    values,
    setFieldValue,
  });

  const history = useHistory();
  const { search } = useLocation();

  const handleSkip = () => {
    history.push({pathname: `${onboardValues.basePath}/vendors`, search});
  }

  return (
    <StyledForm>
      {automaticBankVerificationEnabled &&
        <StyledCalloutContainer>
          <StyledCalloutAction color='tagGreen'>
            {isFinicityConnected(finicityCustomerData) ?
              <FlexColumn>
                <H2 bold>Bank Account Verified</H2>
                <BodyText>Please press "Skip Manual Bank" at the bottom of the page to move forward.</BodyText>
              </FlexColumn>
              :
              <FlexColumn>
                <H2 bold>Want To Skip This Step?</H2>
                <BodyText>We can fill this page for you. Just click the button to easily verify your account.</BodyText>
              </FlexColumn>
            }
            <ConnectBankButton onSuccess={refetchFinicityCustomerData}>Easy Bank Verification</ConnectBankButton>
          </StyledCalloutAction>
        </StyledCalloutContainer>
      }
      <StyledTitleContainer>
        <H2 bold>Manually Enter Bank Info</H2>
        <BodyText>Help us verify your bank account by providing the following information</BodyText>
      </StyledTitleContainer>
      {!requireBankInfo ? (
        <StyledCheckboxWrapper>
          <Checkbox checked={noBankInfo} onChange={noBankInfoClickHandle}>
            <BodyText bold>I don't have bank information available</BodyText>
          </Checkbox>
        </StyledCheckboxWrapper>
      ) : false}
      <FieldArray
        name='manualBanks'
        render={(arrayHelpers) => (
          <>
            {
              values.manualBanks ? values.manualBanks.map((_owner, index) => (
                <ManualBankSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  parentKey={`manualBanks[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                  requestBankStatements={requestBankStatements}
                  disabled={noBankInfo}
                />
              )) : false
            }
            { !noBankInfo
              ? (<Button
                secondary
                LeftIcon={Plus}
                onClick={() => arrayHelpers.push(initialManualBankFields(requestBankStatements))}
              >
              Add Bank
              </Button>)
              : false }
          </>
        )}
      />
      <Flex justify='end' gap='0.5rem'>
        {isFinicityConnected(finicityCustomerData) &&
          <StyledNextButton onClick={handleSkip}>Skip Manual Bank</StyledNextButton>
        }
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </Flex>
    </StyledForm>
  )}
;
