import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

export const isValidRoutingNumber = () => Yup.string()
  .matches(/^\d*$/, DEFAULT_ERROR_MESSAGES.NUMBERS)
  .required('No routing number provided.');

export const isValidAccountNumber = () => Yup.string()
  .matches(/^\d*$/, DEFAULT_ERROR_MESSAGES.NUMBERS)
  .required('No account number provided.');

const manualAccountValidation = Yup.object({
  firstname: Yup.string(),
  lastname: Yup.string(),
  accountType: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  accountNumber: isValidAccountNumber(),
  routingNumber: isValidRoutingNumber(), // TODO validate
});

export const manualBankValidation = Yup.object().shape({
  bankName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  bankAddress: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
  contactName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
  contactEmail: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim(),
  contactPhone: Yup.string().when('contactPhone', {
    is: (value: string) => value?.length > 0,
    then: Yup.string().phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
    otherwise: Yup.string(),
  }),
  contactTitle: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
  accounts: Yup.array().of(manualAccountValidation),
},
[
  ['contactPhone', 'contactPhone']
])

export const bankFormValidation = Yup.object().shape({
  manualBanks: Yup.array().of(manualBankValidation),
})

