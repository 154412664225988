import React, { useContext } from 'react';

import { CREDIT_TERMS } from '@/constants';

import { IVRCustomField } from '@/types/vrCustomField';

import { H2 } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { StyledFormRow } from '@/components/designSystem/Form/styled';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { get, getOr } from 'lodash/fp';

import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm
} from '../../styled';
import { VRCustomField } from '../../VRCustomField';
import { useFormikContext } from 'formik';

interface IProps {
  CACompanyName: string,
  customFields: IVRCustomField[],
  refData: any
}

export const ReferenceStepForm = ({ CACompanyName, customFields, refData }: IProps) => {
  const getCurrency = (refData: any) => {
    const defaultCurrency = get('getReferralData.forCompany.settings.currency', refData);
    const referralCurrency = getOr(defaultCurrency, 'getReferralData.currency', refData);
    if (referralCurrency) return referralCurrency;
    return defaultCurrency;
  }

  const currency = getCurrency(refData);

  const { setFieldValue } = useFormikContext();

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Please complete the vendor credit reference for our mutual customer, {CACompanyName}</H2>
      </StyledTitleContainer>
      <StyledFormRow>
        <DatePickerField
          required
          name='customerSince'
          label='Customer Since'
        />
        <SelectField creatable required name='creditTerms' label='Terms' options={CREDIT_TERMS} />
      </StyledFormRow>
      <StyledFormRow>
        <CurrencyField required name='creditLimit' label='Credit Limit' currency={currency} />
        <CurrencyField required name='creditBalancePastDue' label='Past Due Balance' currency={currency} />
      </StyledFormRow>
      <StyledFormRow>
        <CurrencyField required name='lastPaymentAmount' label='Last Payment Amount' currency={currency} />
        <DatePickerField required name='lastPaymentOn' label='Last Payment Date' />
      </StyledFormRow>
      <StyledFormRow>
        <CurrencyField required name='creditBalanceHigh' label='High Credit Balance' currency={currency} />
        <TextField required type='number' name='averageDaysToPay' label='Average Days To Pay' placeholder='' />
      </StyledFormRow>
      <StyledFormRow>
        {
          customFields.map((customField) => (
            <VRCustomField {...customField} key={customField.id} setFieldValue={setFieldValue} />
          ))
        }
      </StyledFormRow>
      <TextAreaField name='comments' label={`Any Other Comments About ${CACompanyName}?`} placeholder='' />
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )};
